/*
 * @Author: Kaiser
 * @Date: 2019-06-10 10:26:30
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-07-07 11:37:08
 */

const getter = {
  // 返回当前的页面标签数组
  tabs(state) {
    return state.tabs;
  },
};
export default getter;
