/*
 * @Author: Jason
 * @Date: 2019-10-16 15:13:41
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-18 10:26:16
 * @Description: 品牌相关路由
 */
import asyncImport from '@/utils/import_production';

const routes = [
  {
    name: 'brand_list',
    path: '/brand_list',
    meta: {
      name: '品牌列表',
      category: 'menu',
    },
    component: asyncImport('/product/brand_list'),
  },
  {
    name: 'brand_certification',
    path: '/brand_certification',
    meta: {
      name: '品牌认证',
      category: 'menu',
    },
    component: asyncImport('/product/brand_certification'),
  },
  {
    name: 'category_management',
    path: '/category_management',
    meta: {
      name: '商品品类管理',
      category: 'menu',
    },
    component: asyncImport('/product/category_management'),
  },
];

export default routes;
