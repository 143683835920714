/*
 * @Author: Kaiser
 * @Date: 2019-07-26 14:35:00
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-30 14:18:40
 * @Description: 用户登录相关请求的定义
 */
import Request from '../request';

class UserManager extends Request {
  constructor() {
    super({ module: 'usercenter' });
  }

  /**
   * 获取验证图片
   * @param {string} mobile
   * @returns {Promise}
   */
  getVerrificationCodeImg(mobile) {
    return this.get({
      url: '/platform/api/nologin/getVerify',
      data: { phone: mobile },
    });
  }
  /**
   * 用户名密码登录
   * @param {object} data
   * @returns {Promise}
   */
  getLogin(data) {
    return this.post({
      url: '/platform/api/nologin/login',
      data,
      options: { type: 'multipart' },
    });
  }
  /**
   * 获取用户数据
   * @param {object} data
   * @returns {Promise}
   */
  getUserInfo() {
    return this.get({
      url: '/platform/api/user/findUserInfo',
    });
  }
  /**
   * 退出当前用户
   * @param {} data
   */
  postLogOut(token) {
    return this.post({
      url: '/platform/api/user/logout',
      data: { token },
      options: { type: 'multipart' },
    });
  }
  /**
   * 获取菜单权限
   */
  getRoleResource() {
    return this.get({ url: '/api/user/findRoleResouce' });
  }
  /**
   * 修改密码
   */
  updatePassword(data) {
    return this.post({
      url: '/platform/api/user/updatePassword',
      data,
      options: { type: 'multipart' },
    });
  }
  /**
   * list
   */
  getCustomer(data) {
    return this.get({
      url: '/user/findUserList',
      data,
    });
  }
  getAddr(data) {
    return this.get({
      url: '/user/findUserAddrs',
      data,
    });
  }
  getShopCustomerGroup(data) {
    return this.get({
      url: '/user/findShopCustomerGroup',
      data,
    });
  }
  getProductGroupList(data) {
    return this.get({
      url: '/user/findProductGroupList',
      data,
    });
  }
  getDetail(data) {
    return this.post({
      url: '/user/findDetail',
      data,
    });
  }
  getProductList(data) {
    return this.get({
      url: '/user/product/findList',
      data,
    });
  }
  getBrandList(data) {
    return this.get({
      url: '/user/findProductBrandList',
      data,
    });
  }
}

export default new UserManager();
