/*
 * @Author: lxq
 * @Date: 2019-07-10 16:41:53
 * @Last Modified by: Kaiser
 * @Last Modified time: 2021-03-19 11:36:30
 * @Last Modified time: 2020-04-23 17:24:44
 * @Description: 输入校验相关规则定义
 */
import { isArray } from '@/utils/type';

const regular = {
  phone: {
    reg: /(^(\d{3,4}-)?\d{7,8})$|(1[3|5|6|7|8|9]\d{9})/,
    meg: '请输入正确电话或手机号',
  },
  mobile: {
    reg: /^1[3-9]\d{9}$/,
    meg: '请输入正确手机号',
  },
  email: {
    reg: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
    meg: '请输入正确邮箱',
  },
  licenseNo: {
    reg: /^\d{15}|\d{18}$/,
    meg: '请填写正确商户营业执照号',
  },
  idCard: {
    reg: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
    meg: '请填写正确身份证号码',
  },
  backCardNo: {
    reg: /^\d{16,19}$/,
    meg: '请填写正确银行账号',
  },
  barCode: {
    reg: /^\d{13}$|^$/,
    meg: '请填写正确商品条码',
  },
  userPassword: {
    reg: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
    meg: '包含字母和数字，长度为8-16位',
  },
  // product
  stock: /^([1-9]\d*|[0]{1,1})$/,
  webhttp: /^https|http/,
  isNum: /^([1-9][0-9]*){1,5}$/,
  onlyCword: /^[~!@#$%^&*()/|,.<>?"'();:_+-=[\]{}a-zA-z0-9\u4e00-\u9fa5]+$/,
  textCheck: /^((?=[\x21-\x7e]+)|[A-Za-z0-9\u4e00-\u9fa5\s]|[\u0060|\u00b7|\u201c|\u201d|\u2018|\u2019|\u2014-\u2026|\u0021-\u002c|\u002e-\u002f|\u003a-\u003f|\u2200-\u22ff|\uFB00-\uFFFD|\u2E80-\u33FF])+$/,
};
// 登录页面
const login = {
  phone: [
    { required: true, message: '请输入联系人手机号', trigger: 'blur' },
    {
      pattern: regular.mobile.reg,
      message: regular.mobile.meg,
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
  ],
  vCode: [
    {
      required: true,
      message: '请输入验证码',
      trigger: 'blur',
    },
    { min: 4, max: 4, message: '请输入四位验证码', trigger: 'blur' },
  ],
};

const customer = {
  // 客户管理
  customer_info_rule: {
    contactPhone: [
      { required: true, message: '请输入联系人手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: regular.mobile.meg,
        trigger: 'blur',
      },
    ],
    contactName: [
      { required: true, message: '请输入联系人名称', trigger: 'blur' },
    ],
    sellerRemark: [
      { required: true, message: '请输入客户名称', trigger: 'blur' },
    ],
    shopCustomerGroupid: [
      { required: true, message: '请选择客户价格体系', trigger: 'change' },
    ],
  },
  customer_addr_rule: {
    contactName: [
      { required: true, message: '请输入收货人名称', trigger: 'blur' },
    ],
    contactPhone: [
      { required: true, message: '请输入联系电话', trigger: 'blur' },
      {
        pattern: /^\d+?/,
        message: regular.phone.meg,
        trigger: 'blur',
      },
    ],
  },
  // 客户价格体系
  customer_shop_group_rule: {
    name: [
      { required: true, message: '请输入客户价格体系名称', trigger: 'blur' },
    ],
  },
  // 修改业务员和客服弹窗的校验规则
  change_sales_man_and_server: {
    // 业务员
    sellerUserId: [
      { required: true, message: '请选择业务员', trigger: 'blur' },
    ],
    // 客服
    sellerCustomerId: [
      { required: true, message: '请选择客服', trigger: 'blur' },
    ],
  },
};
const product = {
  // 特殊品牌分类
  product_group_rule: {
    name: [
      { required: true, message: '请输入特殊品牌分类名称', trigger: 'blur' },
    ],
  },
  // 客户价格体系
  customer_group_rule: {
    name: [
      { required: true, message: '请输入客户价格体系名称', trigger: 'blur' },
    ],
  },
  // 申请新增品牌
  add_brand_rule: {
    name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
  },
  // 创建商品
  crate_goods_rules: {
    brandId: [{ required: true, message: '请选择商品品牌', trigger: 'blur' }],
    model: [{ required: true, message: '请填写商品型号', trigger: 'blur' }],
    category: [{ required: true, message: '请选择商品品类', trigger: 'blur' }],
    productGroupId: [
      { required: true, message: '请选择商品品牌分类', trigger: 'blur' },
    ],
  },
  // 创建商品
  generate_order: {
    spuId: [{ required: true, message: '请选择商品', trigger: 'blur' }],
    skuId: [{ required: true, message: '请选择规格', trigger: 'blur' }],
    quantity: [
      { required: true, message: '请输入数量', trigger: 'blur' },
      {
        required: true,
        validator: (rule, value, callback) => {
          if (value === '0' || value === 0) {
            callback(new Error('商品数量不能少于最小起订量'));
          } else {
            callback();
          }
        },
        trigger: 'blur',
      },
    ],
    pricePay: [{ required: true, message: '请输入价格', trigger: 'blur' }],
  },
};
// 店铺
const shop = {
  // 店铺基本信息编辑
  shop_info_rule: {
    corpName: [{ required: true, message: '请输入商家名称', trigger: 'blur' }],
    mainProvinceCode: [
      { required: true, message: '请选择店铺所在地', trigger: 'change' },
    ],
    contactName: [
      { required: true, message: '请输入负责人', trigger: 'change' },
    ],
    contactPhone: [
      { required: true, message: '请输入负责人手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: '请输入负责人手机号',
        trigger: 'blur',
      },
    ],
    receiveName: [
      { required: true, message: '请输入收款微信认证姓名', trigger: 'change' },
    ],
    receivePhone: [
      { required: true, message: '请输入收款微信绑定手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: '请输入收款微信绑定手机号',
        trigger: 'blur',
      },
    ],
  },
  // 店铺编辑
  shop_edit_rule: {
    shopName: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
    businessPhone: [
      { required: true, message: '请输入业务联系电话', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: '请输入正确业务联系电话',
        trigger: 'blur',
      },
    ],
    mainProvinceCode: [
      { required: true, message: '请选择所在地', trigger: 'change' },
    ],
    address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
  },
  // 店铺创建
  shop_create_rule: {
    socialCreditCode: [
      {
        required: true,
        validator: (rules, value, callback) => {
          if (/^15|18$/.test(value.length)) {
            callback();
            return;
          }
          callback(new Error('请填写15或18位的正确统一社会信用代码'));
        },
        trigger: 'blur',
      },
    ],
    corpName: [{ required: true, message: '请填写公司名称', trigger: 'blur' }],
    contactName: [
      { required: true, message: '请填写负责人姓名', trigger: 'blur' },
    ],
    contactPhone: [
      { required: true, message: '请填写正确的负责人手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: '请填写正确的负责人手机号',
        trigger: 'blur',
      },
    ],
    shopName: [{ required: true, message: '请填写店铺名称', trigger: 'blur' }],
    businessPhone: [
      {
        required: true,
        message: '请填写正确的业务联系手机号',
        trigger: 'blur',
      },
      {
        pattern: regular.mobile.reg,
        message: '请填写正确的业务联系手机号',
        trigger: 'blur',
      },
    ],
    receiveName: [
      { required: true, message: '请输入收款微信认证姓名', trigger: 'change' },
    ],
    receivePhone: [
      { required: true, message: '请输入收款微信绑定手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: regular.mobile.meg,
        trigger: 'blur',
      },
    ],
    mainProvinceCode: [
      { required: true, message: '请选择店铺所在地', trigger: 'change' },
    ],
    address: [{ required: true, message: '请填写详细地址', trigger: 'blur' }],
    headerUrl: [{ required: true, message: '请上传门头照', trigger: 'change' }],
    businessPlaceUrl: [
      { required: true, message: '请上传营业场所照', trigger: 'change' },
    ],
    businessLicenseUrl: [
      { required: true, message: '请上传营业执照', trigger: 'change' },
    ],
    idCardUrl: [
      {
        required: true,
        validator: (rules, value, callback) => {
          if (isArray(value) && value.length === 2) {
            callback();
            return;
          }
          callback(new Error('请上传身份证正反面两张照片'));
        },
        trigger: 'change',
      },
    ],
  },
};
// 角色
const user = {
  // 成员
  user_add_or_edit_rule: {
    realName: [{ required: true, message: '请输入成员名称', trigger: 'blur' }],
    userName: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: regular.mobile.meg,
        trigger: 'blur',
      },
    ],
    userPassword: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        min: 8,
        max: 16,
        message: '密码长度必须在8-16位',
        trigger: 'blur',
      },
    ],
    email: [
      {
        pattern: regular.email.reg,
        message: regular.email.meg,
        trigger: 'blur',
      },
    ],
  },
  // 客服和业务员
  employmee_add_or_edit_rule: {
    name: [{ required: true, message: '请输入成员名称', trigger: 'blur' }],
    phone: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
      {
        pattern: regular.mobile.reg,
        message: regular.mobile.meg,
        trigger: 'blur',
      },
    ],
  },
};

const decoration = {
  decoration: {
    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
    iconUrl: [{ required: true, message: '请选择图标', trigger: 'blur' }],
  },
};

const state = {
  // 客户
  customer,
  // 商品
  product,
  // 登录
  login,
  // 店铺
  shop,
  // 店铺装修
  decoration,
  // 成员
  user,
};
export default state;
