/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Lhy
 * @Last Modified time: 2020-04-22 11:04:11
 * @Description: user相关的mutation
 */
import clearState from '@/utils/clear_state';
import { isArray, isString } from '@/utils/type';
import { getMenu } from '@/utils/get_routes';
import simpleDeepClone from '@/utils/clone';
import { isObject } from 'util';
import { hasOwnProperty } from '@/utils/object';

import { initState } from './user_state';

const mutation = {
  /**
   * 清除/初始化 state 中的数据
   */
  clearState(state) {
    clearState(state, initState);
  },
  /**
   * 保存用户令牌
   * @param {object} state
   * @param {string} token
   */
  saveToken(state, token) {
    if (!isString(token) || !token) {
      throw new Error('please enter the correct token');
    }
    state.token = token;
    localStorage.setItem('token', token);
  },
  /**
   * 移除用户令牌
   * @param {object} state
   * @param {string} token
   */
  removeToken(state) {
    state.token = '';
    localStorage.removeItem('token');
  },
  /**
   * 保存权限路由
   * @param {*} state
   * @param {Array} routes
   */
  saveRoutes(state, routes) {
    if (!isArray(routes)) {
      throw new Error('please enter the correct type of routes');
    }
    state.routes = simpleDeepClone(routes);
  },
  /**
   * 设置 nav menu
   * @param {Object} state
   * @param {Array} routes
   */
  setNavMenu(state, routes) {
    if (!isArray(routes)) {
      throw new Error('please enter the correct type of routes');
    }
    state.menu = getMenu(routes);
  },
  /**
   * 保存用户信息
   * @param {Object} state
   * @param {object} object
   */
  saveUserInfo(state, info) {
    if (!isObject(info)) {
      throw new Error('please enter the correct type of user info');
    }
    // state 中有的字段才会保存
    Object.keys(info).forEach((key) => {
      if (info[key] !== undefined && hasOwnProperty(state.user, key)) {
        state.user[key] = info[key];
      }
    });
  },
};
export default mutation;
