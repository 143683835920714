var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * @Author: Kaiser
 * @Date: 2019-07-25 14:20:05
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-14 17:27:10
 * @Description: 获取路由相关方法
 */
import { isNumber } from '../utils/type';
import routes from '../router/routes';
import { NAV_MENU_TYPE_OF_POINT, NAV_MENU_TYPE_OF_MENU, NAV_MENU_TYPE_OF_PAGE, } from '../constant/user';
// 菜单的分类
var TYPE_POINT = NAV_MENU_TYPE_OF_POINT;
// 菜单
var TYPE_MENU = NAV_MENU_TYPE_OF_MENU;
// 菜单的子页面
var TYPE_PAGE = NAV_MENU_TYPE_OF_PAGE;
var ROUTE_ID = 1000000;
/**
 * 生成路由id,menu 下的 page 路由组装时使用
 */
function getRouteId() {
    ROUTE_ID += 1;
    return ROUTE_ID;
}
/**
 * 通过解析后端权限 permission 获取到 路由对象
 * 目前只可能获取 menu 类型的,所以遍历一层即可
 * @param {string} 路由的 name 属性
 * @returns {Route} 找到返回路由,反之返回undefined
 */
function getRouteObj(name) {
    return routes.find(function (el) { return el.name === name; });
}
/**
 * 根据后端权限 permission 中的数据判断
 * 若对象类型(category字段)是 TYPE_MENU or TYPE_PAGE
 * 则能加入 vue-router 的路由中
 * @param {InitialRoute} route
 * @returns {boolean} true or false
 */
function canAddToRoute(route) {
    return route.category === TYPE_MENU || route.category === TYPE_PAGE;
}
/**
 * 组装 menu 下的 page
 * @param {Array<InitialRoute>} routes
 * @returns {Array<Route>}
 */
function getMenuRoutes($routes, parentId) {
    var result = [];
    $routes.forEach(function (route) {
        var nRouteId = getRouteId();
        // 拼接 page 路由
        var temp = __assign({}, route, { meta: {
                id: nRouteId,
                name: route.meta.name,
                category: route.meta.category,
                parentId: parentId,
            } });
        // menu中的page还有子页面,则递归遍历, 目前该情况不存在
        if (route.children) {
            result.push.apply(result, getMenuRoutes(route.children, nRouteId));
        }
        delete temp.children;
        result.push(temp);
    });
    return result;
}
/**
 * 递归遍历routes,生成vue-router所需路由
 * @param {Array<InitialRoute>} routes
 * @returns {Array<Route>}
 */
export function getRoutes(initialRoutes) {
    var result = [];
    initialRoutes.forEach(function (route) {
        // 若是路由,则添加路由
        if (canAddToRoute(route)) {
            // 后端权限对象的 code 属性即可路由中的 name 属性
            var $route = getRouteObj(route.code);
            // 若 $route 不存在
            if (!$route) {
                return;
            }
            // 拼接路由
            var temp = __assign({}, $route, { meta: {
                    name: route.name || $route.meta.name,
                    id: route.id,
                    category: route.category,
                    parentId: route.parentId,
                } });
            // menu 中有 page 若有嵌套,则递归遍历
            if ($route.children) {
                result.push.apply(result, getMenuRoutes($route.children, route.id));
            }
            delete temp.children;
            result.push(temp);
        }
        // 权限路由若有嵌套,则递归遍历
        if (route.children) {
            result.push.apply(result, getRoutes(route.children));
        }
    });
    return result;
}
/**
 * 若路由的类型(category字段)是 TYPE_POINT or TYPE_MENU
 * 则能加入菜单列表中
 * @param {Array} route
 * @returns {boolean} true or false
 */
function canAddToMenu(route) {
    return route.category === TYPE_POINT || route.category === TYPE_MENU;
}
/**
 * 生成渲染菜单所需的数据
 * @param {Array<InitialRoute>} routes
 * @returns {Array<Menu>}
 */
export function getMenu(initialRoutes) {
    var result = [];
    initialRoutes.forEach(function (route) {
        // 若是菜单,则添加
        if (canAddToMenu(route)) {
            var $route = getRouteObj(route.code);
            var temp = {
                id: route.id,
                name: route.name,
                code: route.code,
                /**
                 * path 属性值用于 el-menu 的 index 属性,其值不能为空,为空将不展开菜单
                 * 而 point 类型的其 path 为空,则设置为其 id 值
                 */
                path: ($route && $route.path) || String(route.id),
                category: route.category,
                parentId: route.parentId,
                children: [],
            };
            // 判断是否有子菜单
            if (route.children) {
                temp.children = getMenu(route.children);
            }
            result.push(temp);
        }
    });
    return result;
}
/**
 * 通过 id 从菜单中获取
 * @param {Array<Route>} 路由
 * @param {Number} routeId
 * @return {Route}
 */
export function getRoute($routes, routeId) {
    if (routeId === void 0) { routeId = 0; }
    if (!isNumber(routeId)) {
        throw new Error('this route\'s type of id is error');
    }
    var route;
    var length = $routes.length;
    for (var i = 0; i < length; i += 1) {
        var el = $routes[i];
        // 等于当前节点
        if (el.id === routeId) {
            route = el;
            return route;
        }
        if (el.children) {
            route = getRoute(el.children, routeId);
            // 子节点中找到了
            if (route) {
                return route;
            }
        }
    }
    // 没找到返回 undefined
    return undefined;
}
/**
 * 获取从菜单中找到高亮菜单
 * @param {Array<Route>} routes
 * @returns {Route} 返回需要高亮的菜单
 */
export function getActiveMenu($routes, routeId) {
    if (routeId === void 0) { routeId = 0; }
    // const currentRoute = getRoute($routes, routeId);
    // // 若不存在
    // if (!currentRoute) {
    //   return undefined;
    // }
    // // 存在
    // let result = simpleDeepClone(currentRoute);
    // // 若不是 point 类型的菜单,查找父类
    // if (result.category !== TYPE_MENU) {
    //   result = getActiveMenu(routes, result.parentId);
    // }
    // return result;
    var $routeId = routeId;
    var result;
    while ($routeId && !result) {
        result = getRoute($routes, $routeId);
        if (!result) {
            result = getRoute(routes, $routeId);
        }
        if (result && result.category !== TYPE_MENU) {
            result = undefined;
            $routeId = result.parentId;
        }
        // 当id不存在时
        if (!$routeId) {
            break;
        }
    }
    return result;
}
