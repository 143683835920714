/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Kaiser
 * @Last Modified time: 2021-03-23 10:07:04
 * @Description: 店铺相关常量定义
 */
// 定价方式 - 等级价 - 简单模式
export var PRICE_METHOD_LEVEL_LOW = 1;
// 定价方式 - 等级价 - 高级模式
export var PRICE_METHOD_LEVEL_HIGH = 2;
// 定价方式 - 阶梯价
export var PRICE_METHOD_QUANTITY = 3;
// 主营市场中特殊的code,在选择主营市场时,将不展示
export var CODES_OF_SPECIAL_MARKET_REGION = [
    '500200',
    '659000',
];
// 店铺审核的状态
export var STATUS_OF_SHOP_CERTIFICATION = [
    {
        status: 0,
        text: '待审核',
    },
    {
        status: 1,
        text: '已通过',
    },
    {
        status: 2,
        text: '已拒绝',
    },
];
// B与b的供采关系
export var RELATIONS_STATUS = {
    // 打开
    OPEN: 0,
    // 关闭
    CLOSE: 1,
};
// 0-虚拟(游离)用户
export var STATUS_OF_VIRTUALUSER = 0;
// 1-未开店,未提交过开店申请
export var STATUS_OF_SHOP_NOTSUBMIT = 1;
// 2-已审核成功
export var STATUS_OF_SHOP_OPENED = 2;
// 3-开店申请审核中
export var STATUS_OF_SHOP_SUBMITTING = 3;
// 4-开店申请审核失败
export var STATUS_OF_SHOP_SUBMITFAILED = 4;
// shopType 字段 开店平台是在飞耕的类型值
export var TYPE_OF_FG_SHOP = 0;
export var LABEL_OF_FG = '飞耕批发网';
// shopType 字段 开店平台是在飞耕批发网的类型值
export var TYPE_OF_JFHJ_SHOP = 1;
export var LABEL_OF_JFHJ = '街坊行家';
