/*
 * @Author: Kaiser
 * @Date: 2019-07-25 14:20:05
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-08-30 16:20:53
 * @Description: ui state
 */

import simpleDeepClone from '@/utils/clone';

// session 中若有 tabs 的缓存则取之,反之则初始化
const initTabs = [
  {
    // 路由的名字
    name: 'home',
    meta: {
      // 用于标签的名字
      name: '首页',
    },
    fullPath: '/',
    closable: false,
  },
];
const tabs = JSON.parse(sessionStorage.getItem('tabs')) || initTabs;
const initState = {
  // 导航菜单是否收缩
  bIsNavMenuCollapse: false,
  tabs: initTabs,
  // 应用中页面各自定义的刷新事件
  refreshEvents: {
    // pageName: pageRefreshEvent,
  },
};
const state = simpleDeepClone({ ...initState, tabs });
export { initState };
export default state;
