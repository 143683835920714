/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-08 16:31:14
 * @Description: user相关的getter
 */

const getter = {
  // 获取用户令牌
  token(state) {
    return state.token;
  },
  // 用户是否登录
  isUserLogin(state) {
    return !!state.token;
  },
  // 用户信息
  user(state) {
    return state.user;
  },
  // 当前用户的路由
  routes(state) {
    return state.routes;
  },
  //  获取菜单
  menu(state) {
    return state.menu;
  },
};
export default getter;
