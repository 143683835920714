/**
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-29 12:08:26
 * @Description: 程序入口vue
 */
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    // 在页面刷新之前保存当前的tabs
    window.addEventListener('beforeunload', () => {
      this.$store.dispatch('ui/saveTabs');
    });
    this.$nextTick(() => {
      // 获取权限路由
      this.$store.dispatch('user/setRoleResource');
      // 获取用户数据
      this.$store.dispatch('user/saveUserInfo');
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
