/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-08 11:52:38
 * @Description:路由注册
 */
import Vue from 'vue';
import Router from 'vue-router';
import asyncImport from '@/utils/import_production';

Vue.use(Router);

const router = new Router({
  routes: [
    // 登录页面
    {
      path: '/login',
      name: 'login',
      component: asyncImport('/login'),
    },
  ],
});

// const timer = null;
function refresh(routeName) {
  if ($vue) {
    const target = $vue.$store.getters['ui/tabs'].find(
      tab => tab.name === routeName,
    );
    // 若缓存中存在的页面才会刷新
    if (target) {
      $vue.$store.dispatch('ui/refreshPage', routeName);
    }
    return;
  }
  // 延时刷新
  new Promise((resolve) => {
    resolve(true);
  }).then(() => {
    refresh();
  });
}
// 全局守卫
router.beforeEach((to, from, next) => {
  new Promise((resolve) => {
    resolve(true);
  }).then(() => {
    if (sessionStorage.getItem('refresh') === '1') {
      sessionStorage.setItem('refresh', '0');
      refresh(to.name);
    }
  });
  next(true);
});

// 全局守卫
router.afterEach((to) => {
  // 跳转的不是登录页面且未登录
  if (!window.$vue) {
    return;
  }
  // 若跳转到的不是登录页且用户未登录,页面跳转至登录页面
  if (to.name !== 'login' && !window.$vue.$store.getters['user/isUserLogin']) {
    router.push('/login');
    return;
  }

  // 若跳转到的是登录页且用户登录,页面跳转至首页
  if (to.name === 'login' && window.$vue.$store.getters['user/isUserLogin']) {
    router.replace('/home');
  }
});

export default router;
