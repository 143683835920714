/*
 * @Author: Kaiser
 * @Date: 2020-04-09 10:32:53
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-14 17:21:30
 * @Description:
 */
import asyncImport from '@/utils/import_production';

const routes = [
  {
    path: '/order_list',
    name: 'order_list',
    meta: {
      name: '订单列表',
      category: 'menu',
    },
    component: asyncImport('/order/order_list'),
    children: [
      {
        path: '/order_detail',
        name: 'order_detail',
        meta: {
          name: '订单详情',
        },
        component: asyncImport('/order/order_detail'),
      },
    ],
  },

  {
    path: '/scheduled_order_list',
    name: 'scheduled_order_list',
    meta: {
      name: '预定订单列表',
      category: 'menu',
    },
    component: asyncImport('/order/scheduled_order_list'),
    children: [
      {
        path: '/scheduled_order_detail',
        name: 'scheduled_order_detail',
        meta: {
          name: '预定订单详情',
        },
        component: asyncImport('/order/scheduled_order_detail'),
      },
    ],
  },
];
export default routes;
