/*
 * @Author: lxq
 * @Date: 2019-07-10 16:49:49
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-08-21 11:23:20
 */
const getter = {
  // 客户
  customer(state) {
    return state.customer;
  },
  // 商品
  product(state) {
    return state.product;
  },
  // 客户
  login(state) {
    return state.login;
  },
  // 店铺
  shop(state) {
    return state.shop;
  },
  // 店铺
  decoration(state) {
    return state.decoration;
  },
  // 成员
  user(state) {
    return state.user;
  },
};
export default getter;
