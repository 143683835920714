/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-08-28 15:09:20
 * @Description: 对象类型相关方法拓展
 */
/**
 * 判断key是都是obj自身的键,不遍历原型链
 * @param {object} obj
 * @param {string} key
 */
export function hasOwnProperty(target, key) {
    return Object.prototype.hasOwnProperty.call(target, key);
}
/**
 * 判断对象是否为空
 * @param {object} obj
 * @param {string} key
 */
export function isObjectEmpty(target) {
    return Object.keys(target).length === 0;
}
export default {
    hasOwnProperty: hasOwnProperty,
    isObjectEmpty: isObjectEmpty,
};
