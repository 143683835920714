/*
 * @Author: Kaiser
 * @Date: 2019-06-10 10:26:24
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-29 12:03:55
 * @Description: user相关的action
 */

import redirect from '@/router/redirect';
import permission from '@/router/permission';
import { getRoutes } from '@/utils/get_routes';
import asyncImport from '@/utils/import_production';

import Login from '@/api/user/api_login';
import { LOGIN_SUCCESSFUL } from '@/constant/user';

const action = {
  /**
   * 退出登录
   * 清除token,跳转到登录页面
   * 清楚store中的处理 todo
   */
  logout() {
    Promise.all([
      $vue.$store.dispatch('ui/clearState'),
      $vue.$store.dispatch('user/clearState'),
    ]).then(() => {
      $vue.$router.push('/login');
      // window.location.reload();
    });
  },
  /**
   * 清除/初始化 state 中的数据
   * @param {Object} vuex 默认参数
   */
  clearState({ commit }) {
    commit('clearState');
    commit('removeToken');
  },
  /**
   * 设置菜单路由权限
   */
  async setRoleResource({ dispatch, getters }) {
    return new Promise((resolve) => {
      // 判断用户是否是登录状态,若不是则阻断
      if (!getters.isUserLogin) {
        return;
      }
      const $permission = [...permission];
      // 生成vue路由数据
      const routes = getRoutes($permission);
      // 保存路由数据
      dispatch('saveRoutes', routes);
      // 保存菜单数据
      dispatch('setNavMenu', $permission);
      // 动态添加路由
      $vue.$router.addRoutes([
        // 主页面
        {
          path: '/',
          name: 'layout',
          redirect: '/home',
          // 这一级将使用keep-alive进行缓存
          component: asyncImport('/layout/layout'),
          // 此处的路由都将是平级的,没有嵌套的
          children: [
            // 重定向
            ...redirect,
            // 用户路由
            ...routes,
          ],
        },
      ]);
      resolve(LOGIN_SUCCESSFUL);
      // 获取路由权限,并设置菜单
      // Login.getRoleResource().then((value) => {
      //   let $permission = value.data;
      //   if (process.env.BUILD_ENV === 'local') {
      //     $permission = [...permission];
      //   }
      //   // 生成vue路由数据
      //   const routes = getRoutes($permission);
      //   // 保存路由数据
      //   dispatch('saveRoutes', routes);
      //   // 保存菜单数据
      //   dispatch('setNavMenu', $permission);
      //   // 动态添加路由
      //   $vue.$router.addRoutes([
      //     // 主页面
      //     {
      //       path: '/',
      //       name: 'layout',
      //       redirect: '/home',
      //       // 这一级将使用keep-alive进行缓存
      //       component: asyncImport('/layout/layout'),
      //       // 此处的路由都将是平级的,没有嵌套的
      //       children: [
      //         // 重定向
      //         ...redirect,
      //         // 用户路由
      //         ...routes,
      //       ],
      //     },
      //   ]);
      //   resolve(LOGIN_SUCCESSFUL);
      // });
    });
  },
  /**
   * 保存用户 token
   * @param {Object} vuex 默认参数
   * @param {*} routes
   */
  saveToken({ commit }, token) {
    commit('saveToken', token);
  },
  /**
   * 移除用户 token
   * @param {Object} vuex 默认参数
   * @param {*} routes
   */
  removeToken({ commit }) {
    commit('removeToken');
  },
  /**
   * 保存权限路由
   * @param {Object} vuex 默认参数
   * @param {Array} routes 从后端获取的用户权限路由
   */
  saveRoutes({ commit }, routes) {
    commit('saveRoutes', routes);
  },
  /**
   * 设置 nav menu
   * @param {Object} vuex 默认参数
   * @param {Array} routes 从后端获取的用户权限路由
   */
  setNavMenu({ commit }, routes) {
    commit('setNavMenu', routes);
  },
  /**
   * 保存用户信息
   * @param {Object} vuex 默认参数
   * @param {object} 用户信息
   */
  async saveUserInfo({ commit, getters }) {
    const result = await new Promise((resolve) => {
      // 判断用户是否是登录状态,若不是则阻断
      if (!getters.isUserLogin) {
        $vue.$router.replace('/login');
        return;
      }
      // 若登录获取用户数据
      Login.getUserInfo().then((user) => {
        commit('saveUserInfo', user.data);
        resolve(resolve);
      });
    });
    return result;
  },
};

export default action;
