/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-14 10:42:30
 * @Description: 本地权限保存
 */
import { NAV_MENU_TYPE_OF_POINT, NAV_MENU_TYPE_OF_MENU } from '@/constant/user';
import { hasOwnProperty } from '@/utils/object';

const permission = [
  {
    category: NAV_MENU_TYPE_OF_MENU,
    children: [],
    name: '首页',
    code: 'home',
  },
  {
    category: NAV_MENU_TYPE_OF_POINT,
    name: '商品管理',
    code: 'goods',
    children: [
      // {
      //   category: NAV_MENU_TYPE_OF_MENU,
      //   code: 'good_list',
      //   name: '商品列表',
      // },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'brand_list',
        name: '品牌列表',
      },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'brand_certification',
        name: '品牌认证',
      },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'category_management',
        name: '商品品类管理',
      },
    ],
  },
  {
    category: NAV_MENU_TYPE_OF_POINT,
    name: '商家店铺',
    code: 'shop',
    children: [
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'shop_list',
        name: '店铺列表',
      },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'merchant_list',
        name: '商家列表',
      },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'shop_certification_list',
        name: '开店认证',
      },
    ],
  },
  {
    category: NAV_MENU_TYPE_OF_POINT,
    name: '订单监控',
    code: 'order',
    children: [
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'order_list',
        name: '销售订单列表',
      },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'scheduled_order_list',
        name: '预定订单列表',
      },
    ],
  },
  {
    category: NAV_MENU_TYPE_OF_POINT,
    name: '平台设置',
    code: 'platform',
    children: [
      // {
      //   category: NAV_MENU_TYPE_OF_MENU,
      //   code: 'payment_parameter_setting',
      //   name: '缴费参数设置',
      // },
      {
        category: NAV_MENU_TYPE_OF_MENU,
        code: 'platform_parameter_setting',
        name: '平台参数设置',
      },
    ],
  },
];

let id = 0;
/**
 * 生成id
 */
function generateId() {
  id += 1;
  return id;
}

/**
 * 生成权限数据
 * 这里的数据给到后端!!
 */
function generate(arr, parent) {
  const result = arr.map((el, index) => {
    const temp = {
      category: el.category,
      id: generateId(),
      name: el.name,
      code: el.code,
      parentId: (parent && parent.parentId) || 0,
      sort: index + 1,
    };
    if (hasOwnProperty(el, 'children') && el.children.length) {
      temp.children = generate(el.children, temp);
    }
    return temp;
  });
  return result;
}

export default generate(permission);
