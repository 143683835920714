/*
 * @Author: Kaiser
 * @Date: 2019-08-16 15:13:51
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-08-16 15:18:09
 * @Description: 退出登录时,清除store时使用的函数,即将对象恢复初始状态
 */
import { hasOwnProperty } from './object';
import { isObject, isArray } from './type';
import simpleDeepClone from './clone';
function clearState(state, initState) {
    // 遍历需要初始化的对象上的key
    Object.keys(state).forEach(function (key) {
        // 若是对象的初始化状态中的参数进行初始化
        if (hasOwnProperty(initState, key)) {
            // 若是对象 or 数组等引用类型,使用深拷贝
            if (isObject(state[key]) || isArray(state[key])) {
                state[key] = simpleDeepClone(initState[key]);
                return;
            }
            // 其他类型直接修改值
            state[key] = initState[key];
        }
    });
}
export default clearState;
