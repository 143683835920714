/*
 * @Author: Kaiser
 * @Date: 2019-07-09 11:35:33
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-09 11:48:16
 */
/**
 * 简易深度复制 不合法的json值将被过滤
 * @param {Object} obj
 */
export function simpleDeepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export default simpleDeepClone;
