/*
 * @Author: Kaiser
 * @Date: 2020-03-19 15:46:29
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-03-19 15:48:38
 * @Description: 全局的过滤器
 */

import { getAddress } from './shop';

export default { getAddress };
