/*
 * @Author: Kaiser
 * @Date: 2019-10-08 09:52:27
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-14 10:44:35
 * @Description:Kaiser
 */
import home from './home';
import shop from './shop';
import order from './order';
import product from './product';
import platform from './platform';

export default [
  // 主页
  ...home,
  // 商家店铺
  ...shop,
  // 商品模块
  ...product,
  // 平台模块
  ...platform,
  // 订单模块
  ...order,
];
