/*
 * @Author: Kaiser
 * @Date: 2019-09-25 12:04:48
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-08 11:46:19
 * @Description: 请求拦截名单
 */
export var REQUEST_INTERCEPT_LIST = [
    // 获取商品列表
    '/api/product/product/findList',
];
export default REQUEST_INTERCEPT_LIST;
