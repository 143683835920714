import asyncImport from '@/utils/import_production';

const routes = [
  {
    name: 'home',
    path: '/home',
    meta: {
      name: '首页',
      category: 'menu',
    },
    component: asyncImport('/home'),
  },
];

export default routes;
