import asyncImport from '@/utils/import_production';

export default [
  {
    path: '/redirect',
    name: 'redirect',
    meta: {
      name: '重定向',
    },
    component: asyncImport('/layout/redirect'),
  },
];
