/*
 * @Author: lxq
 * @Date: 2019-07-10 17:49:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2019-07-10 18:00:45
 */
// import mutation from './rules_mutation';
// import action from './rules_action';
import state from './rules_state';
import getter from './rules_getter';

const app = {
  namespaced: true,
  state,
  getters: getter,
  // mutations: mutation,
  // actions: action,
};

export default app;
