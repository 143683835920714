/*
 * @Author: lxq
 * @Date: 2019-07-31 20:21:18
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-22 13:00:06
 * @Description: 网络请求封装
 */
import { REQUEST_INTERCEPT_LIST } from '@/constant/request_intercept_list';
import { ERROR_CODE_WHITELIST } from '@/constant/error_code_whitelist';
import {
  ERROR_CODE_OF_TOKEN_EXPIRED,
  ERROR_CODE_OF_USERNAME_EXPIRED,
  ERROR_CODE_OF_INEFFECTIVE_SHOP,
} from '@/constant/user';
import { Loading } from '_modules/element-ui';

import axios from '_modules/axios';
import { isObject, isString } from '@/utils/type';
import apiModules from './api_module';

// 默认request的配置
const DEFAULT_OPTION = {
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
  timeout: 30000,
  baseURL: process.env.API_DOMAIN,
};

let loadingAnimation = null;

/**
 * 展示loading
 */
function showLoading() {
  // 若已有loading,则阻断
  if (loadingAnimation) return;
  const classNameOfMain = '.el-main';
  const nameOfBody = 'body';
  const main = document.querySelector('.el-main');
  loadingAnimation = Loading.service({
    target: main ? classNameOfMain : nameOfBody,
    lock: true,
    text: '数据在加载中',
    spinner: 'el-icon-loading',
    background: 'rgba(255,255,255,0.7)',
    customClass: 'global-loading',
  });
}

let timerOfHideLoading = null;
/**
 * 隐藏
 */
function hideLoading() {
  // 清楚已有的
  clearTimeout(timerOfHideLoading);
  timerOfHideLoading = setTimeout(() => {
    clearTimeout(timerOfHideLoading);
    if (!loadingAnimation) return;
    loadingAnimation.close();
    loadingAnimation = null;
  }, 500);
}

/**
 * 根据网络状态码返回错误信息
 * @param {Number or String} status 网络状态码
 */
function getResponseErrorTip(status) {
  // 错误状态的提示文字
  const RESPONSE_ERROR_TIP = {
    400: '请求参数或语句有误！',
    401: '未授权，请重新登录！',
    403: '拒绝访问！',
    404: '未找到该资源！',
    405: '请求方法未允许！',
    408: '请求超时！',
    500: '服务器端出错！',
    501: '网络未实现！',
    502: '网络错误！',
    503: '服务不可用！',
    504: '网络超时！',
    505: 'http版本不支持该请求！',
  };
  return RESPONSE_ERROR_TIP[status] || '请求错误！';
}

// 请求拦截名单
const interceptList = [...REQUEST_INTERCEPT_LIST];

export default class Request {
  /**
   * 构造函数
   * @param {Object} option 自定义request配置
   * option.module必传，其意为请求的模块名
   */
  constructor(option = {}) {
    if (option.module) {
      // 设置模块的url
      this.moduleUrl = apiModules[option.module];
      if (!this.moduleUrl) {
        throw new Error("the request's module is set incorrectly");
      }
    }
    const $option = { ...DEFAULT_OPTION, ...option };
    delete $option.module;
    this.$http = axios.create($option);

    // 处理化正在处理中的请求
    // pageName:url
    this.$handlingRequests = [];

    // request拦截器
    this.$http.interceptors.request.use(
      (config) => {
        $vue.$store.dispatch('ui/setNProgress', 'start');
        if (config.headers.loading === 1) {
          showLoading();
        }
        return config;
      },
      error => Promise.reject(error),
    );

    // response拦截器
    this.$http.interceptors.response.use(
      (response) => {
        // eslint-disable-next-line no-underscore-dangle
        const url = JSON.parse(response.config.headers.DATA)._url_;
        let data = JSON.stringify('');
        // 若是string,则等于 response.config.data
        if (isString(response.config.data)) {
          data = response.config.data;
        }
        this.removeRequest(this.getUrl(url, data));
        $vue.$store.dispatch('ui/setNProgress', 'done');
        // 当前请求设置了开始loading,则开启
        if (response.config.headers.loading === 1) {
          hideLoading();
        }
        const $response = response.data || response;
        /**
         * 全局错误提示
         * 且当前请求设置的是进行错误提示,no_error_tip为0,1为禁止提示
         * 错误信息存在,但不在错误信息白名单中,则提示
         */
        if (
          response.config.headers.no_error_tip !== 1 &&
          $response.errorMessage &&
          !ERROR_CODE_WHITELIST.includes($response.errorCode)
        ) {
          $vue.$store.dispatch('ui/showErrorMessage', $response.errorMessage);
        }
        // 若是未授权,重新登录
        if (
          $response.errorCode === ERROR_CODE_OF_TOKEN_EXPIRED ||
          $response.errorCode === ERROR_CODE_OF_USERNAME_EXPIRED ||
          $response.errorCode === ERROR_CODE_OF_INEFFECTIVE_SHOP
        ) {
          $vue.$store.dispatch('user/logout');
          return undefined;
        }
        return $response;
      },
      (error) => {
        // eslint-disable-next-line no-underscore-dangle
        const url = JSON.parse(error.config.headers.DATA)._url_;
        let data = JSON.stringify('');
        // 若是string,则等于 response.config.data
        if (isString(error.config.data)) {
          data = error.config.data;
        }
        // 全局错误提示
        this.removeRequest(this.getUrl(url, data));
        const message = getResponseErrorTip(
          error.response && error.response.status,
        );
        $vue.$store.dispatch('ui/showErrorMessage', message);
        hideLoading();
        // 若是未授权,重新登录
        if (error.response.data.errorCode === ERROR_CODE_OF_TOKEN_EXPIRED) {
          $vue.$store.dispatch('user/logout');
          return undefined;
        }
        return Promise.reject(error);
      },
    );
  }

  getUrl(url, data = '') {
    return data ? this.combineUrl(url, JSON.parse(data)) : url;
  }

  /**
   * 获取请求的id
   * @param {string} afterCombineUrl - 组合data之后的url
   */
  // eslint-disable-next-line class-methods-use-this
  getRequestId(afterCombineUrl) {
    const pageName = $vue.$route.name || $vue.$route.path;
    const id = `${pageName}:${afterCombineUrl}`;
    return id;
  }

  /**
   * 判断将要请求的接口是否正在请求中
   * @param {string} id pageName:afterCombineUrl
   * @returns {number} 返货对象在栈中的下标
   */
  isHandlingRequest(id) {
    return this.$handlingRequests.indexOf(id);
  }

  /**
   * 请求入栈
   * @param {string} afterCombineUrl - 组合参数(data)之后的url
   * @returns {boolean} false - 已有正在处理的请求  true - 请求处理成功
   */
  addRequest(afterCombineUrl) {
    // 若不在拦截名单中则不拦截
    if (!interceptList.some(el => afterCombineUrl.indexOf(el) === 0)) {
      return true;
    }
    const id = this.getRequestId(afterCombineUrl);
    // 若将要请求的接口没有正在处理的,将当前请求加入正在请求的栈中
    if (this.isHandlingRequest(id) === -1) {
      this.$handlingRequests.push(id);
      return true;
    }
    return false;
  }

  /**
   * 请求出栈
   * @param {string} afterCombineUrl - 为拼接data之后的url
   */
  removeRequest(afterCombineUrl) {
    // 若不在拦截名单中则不拦截
    if (!interceptList.some(el => afterCombineUrl.indexOf(el) === 0)) {
      return;
    }
    const id = this.getRequestId(afterCombineUrl);
    const index = this.isHandlingRequest(id);
    if (index !== -1) {
      this.$handlingRequests.splice(index, 1);
    }
  }
  /**
   * 组合DATA中的url参数
   * @param {string} url
   * @param {object} data
   * @returns {string} 返回组合的结果
   */
  // eslint-disable-next-line class-methods-use-this
  combineUrl(beforeCombineUrl, data, type = 'json') {
    let str = '';
    if (type === 'json') {
      if (isObject(data)) {
        Object.entries(data).forEach((query, index) => {
          str += `${index === 0 ? '' : '&'}${query[0]}=${encodeURIComponent(
            query[1],
          )}`;
        });
      }
    }

    if (type === 'multipart') {
      str = '';
    }
    return beforeCombineUrl + (str && `?${str}`);
  }

  /**
   * 将请求参数封装到headers的DATA中，必要可在这里加密
   * @param {string} url 实际请求的url
   * @param {object} data 请求参数
   */
  setDATA({ url }) {
    // 来自localStorage的token
    const tokenFromLocalStorage = localStorage.getItem('token');
    // 来自store中的token
    const tokenFromStore = $vue.$store.getters['user/token'];
    // 两者都存在,且不相等,说明,用户重新登录了一个账号,重新加载当前页面
    if (
      tokenFromLocalStorage &&
      tokenFromStore &&
      tokenFromLocalStorage !== tokenFromStore
    ) {
      window.location.reload();
      return;
    }
    const $json = JSON.stringify({
      _url_: url,
      // token 处理
      _random_: $vue.$store.getters['user/token'],
      // _length_: 0, // 暂时不需要,留作加密使用
    });
    // 处理请求 url
    this.$http.defaults.headers.DATA = $json;
  }
  /**
   * 设置其余配置项
   * @param {object} options
   * scope: 1-飞撒后台,4-公共服务
   * type: content-type的类型,目前有json和multipart两种
   * noErrorTip: 错误提示是否禁止
   * loading: 页面 or tab级别的加载是否开始
   */
  setOptions({ scope = 4, type = 'json', noErrorTip = 0, loading = 1 }) {
    // 默认是application/json
    this.$http.defaults.headers = {
      ...this.$http.defaults.headers,
      'Content-Type': 'application/json',
    };
    // 判断类型
    if (type === 'multipart') {
      this.$http.defaults.headers = {
        ...this.$http.defaults.headers,
        'Content-Type': 'multipart/form-data',
      };
    }
    // 默认是运营平台
    this.$http.defaults.headers.scope = 4;
    if (scope !== 4) {
      this.$http.defaults.headers.scope = scope;
    }

    // 默认有错误提示
    this.$http.defaults.headers.no_error_tip = 0;
    if (noErrorTip === 1) {
      this.$http.defaults.headers.no_error_tip = 1;
    }

    // 默认展示loading
    this.$http.defaults.headers.loading = 1;
    if (loading === 0) {
      this.$http.defaults.headers.loading = 0;
    }
  }
  /**
   *
   * @param {obj} 设置body中的参数所需的数据
   */
  // eslint-disable-next-line class-methods-use-this
  setData({ data = {}, type = 'json' }) {
    if (type === 'multipart') {
      const params = new FormData();
      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });
      return params;
    }
    return data;
  }

  get({ url, data, options = { type: 'json' } }) {
    return new Promise((resolve, reject) => {
      // 组合url
      const afterCombineUrl = this.combineUrl(url, data, options.type);
      // 入栈,返回值是入栈是否成功
      const flag = this.addRequest(afterCombineUrl);
      if (!flag) {
        reject('当前请求正在处理中..');
        return;
      }
      this.setDATA({ url: afterCombineUrl });
      this.setOptions(options);
      resolve(
        this.$http({
          method: 'get',
          url: this.moduleUrl,
        }),
      );
    });
  }

  post({ url, data, options = { type: 'json' } }) {
    return new Promise((resolve, reject) => {
      // 组合url,post组合的结果仅用于判断是否能发出请求
      const afterCombineUrl = this.combineUrl(url, data, options.type); // post 参数在body中传递
      // 入栈,返回值是入栈是否成功
      const flag = this.addRequest(afterCombineUrl);
      if (!flag) {
        reject('当前请求正在处理中..');
        return;
      }
      this.setDATA({ url });
      // 设置body参数
      const $data = this.setData({ data, type: options.type });
      this.setOptions(options);
      resolve(
        this.$http({
          method: 'post',
          url: this.moduleUrl,
          data: $data || {},
        }),
      );
    });
  }
}
