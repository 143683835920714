/*
 * @Author: Kaiser
 * @Date: 2019-07-11 11:19:04
 * @Last Modified by: lxq
 * @Last Modified time: 2019-08-29 11:47:07
 */
export var LOGIN_SUCCESS = '登录成功';
/**
 * nav_menu 说明
 * 在菜单中只展示 point 和 menu
 * point 是 menu 的父节点, 不能跳转页面
 * menu 是 page 的父节点, 可以跳转页面
 * page 是 子页面,不在 nav_menu 中展示
 *
 */
// nav_menu 中的分类
export var NAV_MENU_TYPE_OF_POINT = 1;
// nav_menu 中的菜单
export var NAV_MENU_TYPE_OF_MENU = 2;
// nav_menu 中的子页面
export var NAV_MENU_TYPE_OF_PAGE = 3;
// 非登录状态
export var NOT_LOGGED_IN = 'not_logged_in';
// 登录成功
export var LOGIN_SUCCESSFUL = 'login_successful';
// 验证码出错的 error code
export var ERROR_CODE_OF_VCODE_ERROR = '20007';
// token 过期
export var ERROR_CODE_OF_TOKEN_EXPIRED = '20006';
// 账号或密码错误
export var ERROR_CODE_OF_USERNAME_EXPIRED = '20003';
// 授权过期
export var ERROR_CODE_OF_AUTH_EXPIRED = '1002';
// 无效店铺
export var ERROR_CODE_OF_INEFFECTIVE_SHOP = '1020007';
