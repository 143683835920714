/*
 * @Author: Kaiser
 * @Date: 2019-08-01 17:36:10
 * @Last Modified by: Jason
 * @Last Modified time: 2019-10-16 16:38:30
 * @Description: 模块与网关url模块的映射关系保存
 */
module.exports = {
  // 品牌模块
  brand: '/api',
  // 商品模块
  product: '/api',
  // 店铺关系
  shop_relation: '/api',
  // 店铺装修
  shop_decoration: '/api',
  // 资讯模块
  cms: '/api',
  // 订单模块
  order: '/api',
  // 收货地址模块
  address: '/api',
  // 支付模块
  payment: '/api',
  // 用户模块
  usercenter: '/api',
  // 客服与业务员模块
  employee: '/api',
  // 公共模块
  general: '/api',
  // 爬虫
  crawler: '/api',
  // 财务结算
  bill: '/api',
  // 公共(图片上传)
  common: '/api',
};
