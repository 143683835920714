/*
 * @Author: Kaiser
 * @Date: 2019-06-10 09:50:36
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-03-19 16:40:03
 * @Description: 程序入口
 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import ElementUI from '_modules/element-ui';
import '_modules/element-ui/lib/theme-chalk/index.css';
import '_modules/normalize.css';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import filters from '@/filters/index';
// 样式
import '@/style/index.scss';
// 引入iconfont
import '@/assets/icon/iconfont';

// https://cn.vuejs.org/v2/api/index.html#productionTip
// false - 启动时不产生生产提示 true - 生成
Vue.config.productionTip = false;
Vue.use(ElementUI);
// Vue.mixin(mixin);
// 注册全局过滤器
Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName]);
});
window.$vue = new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
});
