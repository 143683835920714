/*
 * @Author: Kaiser
 * @Date: 2020-04-10 14:05:40
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-10 14:09:22
 * @Description:  平台设置相关路由
 */

import asyncImport from '@/utils/import_production';

const routes = [
  {
    path: '/platform_parameter_setting',
    name: 'platform_parameter_setting',
    meta: {
      name: '平台参数设置',
      category: 'menu',
    },
    component: asyncImport('/platform/platform_parameter_setting'),
  },
];
export default routes;
