/*
 * @Author: Kaiser
 * @Date: 2019-08-06 11:57:21
 * @Last Modified by: Kaiser
 * @Last Modified time: 2021-03-23 09:31:34
 * @Description: 店铺相关通用方法定义的方法定义
 */
import {
  PRICE_METHOD_LEVEL_LOW,
  PRICE_METHOD_LEVEL_HIGH,
  PRICE_METHOD_QUANTITY,
  STATUS_OF_SHOP_CERTIFICATION,
  TYPE_OF_JFHJ_SHOP,
} from '@/constant/shop';
/**
 * 通过审核状态(数字)返回审核状态(文字)
 * @param {number} auditStatus - 审核状态
 */
export function getStatusText(auditStatus) {
  const temp = STATUS_OF_SHOP_CERTIFICATION.find(
    el => el.status === auditStatus,
  );
  return (temp && temp.text) || '';
}

// 是否是简单模式
export function isLevelLow(priceMethod) {
  return priceMethod === PRICE_METHOD_LEVEL_LOW;
}
// 是否是高级模式
export function isLevelHigh(priceMethod) {
  return priceMethod === PRICE_METHOD_LEVEL_HIGH;
}
// 是否是阶梯价
export function isQuantity(priceMethod) {
  return priceMethod === PRICE_METHOD_QUANTITY;
}

/**
 * 拼接地址
 * @param {object} shop
 * mainProvinceName - 省
 * mainCityName - 市
 * mainCountryName - 区
 * joiner - 连接符
 */
export function getAddress(
  { mainProvinceName = '', mainCityName = '', mainCountryName = '' },
  joiner = '',
) {
  // 未设置
  if (!mainProvinceName) {
    return '';
  }
  const city = mainCityName ? `${joiner}${mainCityName}` : '';
  const country = mainCountryName ? `${joiner}${mainCountryName}` : '';
  return `${mainProvinceName}${city}${country}`;
}

/**
 * 店铺所在地拼接地址
 * @param {object} shop
 * provinceName - 省
 * cityName - 市
 * countryName - 区
 * joiner - 连接符
 */
export function getShopAddress(
  { provinceName = '', cityName = '', countryName = '' },
  joiner = '',
) {
  // 未设置
  if (!provinceName) {
    return '';
  }
  const city = cityName ? `${joiner}${cityName}` : '';
  const country = countryName ? `${joiner}${countryName}` : '';
  return `${provinceName}${city}${country}`;
}

/**
 * 展示 name(remark),若没有remark,展示name
 * @param {object} obj
 * remark - 备注名
 * name - 店铺名
 */
export function getShopName({ name, remark }) {
  // 名字和备注都存在,返回 name(remark)
  if (name && remark && name !== remark) {
    return `${name}(${remark})`;
  }
  // 返回其中一个存在的
  return name || remark;
}
/**
 * 是否是默认的门头照
 * @param {any} headerUrl
 */
export function isDefaultHeaderUrl(headerUrl) {
  return (
    headerUrl ===
    'https://ms-b2b-test.oss-cn-hangzhou.aliyuncs.com/images/product/20190816/c51ebdeb9785e16a1013785d641d4a0a.png'
  );
}

// 是否是街坊行家店铺
export function isJFFJSHOP(shopType) {
  return shopType === TYPE_OF_JFHJ_SHOP;
}

export default getStatusText;
