/*
 * @Author: Kaiser
 * @Date: 2019-06-10 09:51:38
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-10-11 09:10:08
 */
import Vue from 'vue';
import Vuex from '_modules/vuex';
import ui from './ui_store/ui_module';
import user from './user_store/user_module';
import rules from './rules_store/rules_module';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ui,
    user,
    rules,
  },
});
export default store;
