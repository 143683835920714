/*
 * @Author: Kaiser
 * @Date: 2019-08-10 18:45:08
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-08-30 16:33:55
 * @Description: UI模块的mutation
 */
import clearState from '@/utils/clear_state';

import { Message } from 'element-ui';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { initState } from './ui_state';

const mutation = {
  /**
   * 清除/初始化 state 中的数据
   */
  clearState(state) {
    clearState(state, initState);
  },
  /**
   * NProgress动画控制器 start-开始 true-结束
   */
  nprogressHandler: (state, status = 'start') => {
    NProgress[status]();
  },
  /**
   * 展示信息
   * @param data
   * {
   *  type：展示信息类型，依照elementui中Message的类型
   *  msg：展示具体信息
   * }
   */
  messageHandler: (state, data) => {
    if (data.type && Message[data.type]) {
      Message(data);
      return undefined;
    }
    throw new Error('please enter the correct type of information');
  },
  /**
   * 控制导航菜单的显示隐藏
   * @param status false-隐藏 true-显示
   */
  switchNavMenu(state) {
    state.bIsNavMenuCollapse = !state.bIsNavMenuCollapse;
  },
  /**
   * 增加标签
   * @param {Object} state
   * @param {Object} route
   */
  addTab(state, route) {
    if (route.name === 'redirect') return;
    const tabs = state.tabs;
    const newTab = {
      name: route.name,
      meta: {
        // 用于标签的名字
        name: route.meta.name,
      },
      fullPath: route.fullPath,
      closable: route.meta.name !== '首页',
    };
    const index = state.tabs.findIndex(tab => tab.name === route.name);
    // 若标签数据中已有，则返回下标，并刷新tab数据
    if (index !== -1) {
      tabs[index] = newTab;
      return;
    }
    tabs.push(newTab);
  },
  /**
   * 移除当前选择的标签
   * @param {Object} state - store
   * @param {Object} route - 当前需要移除的标签（路由）的名字(name属性)
   */
  removeTab(state, tabName) {
    const index = state.tabs.findIndex(tab => tab.name === tabName);
    if (index > -1) {
      state.tabs.splice(index, 1);
    }
  },
  /**
   * 移除其他标签
   * @param {Object} state - store
   * @param {Object} tabName - 当前需要移除的标签（路由）的名字(name属性)
   */
  removeOtherTabs(state, tabName) {
    const index = state.tabs.findIndex(tab => tab.name === tabName);
    // home标签不关闭
    if (index > 0) {
      const temp = state.tabs[index];
      state.tabs.length = 2;
      state.tabs[1] = temp;
      return;
    }
    state.tabs.length = 1;
  },
  /**
   * 保存tabs到session中
   * @param {Object} state - store
   */
  saveTabs(state) {
    sessionStorage.setItem('tabs', JSON.stringify(state.tabs));
  },
  /**
   * 往刷新事件中添加某页面的刷新事件
   */
  addRefreshEvent(state, { pageName, pageRefreshEvent }) {
    state.refreshEvents[pageName] = pageRefreshEvent;
  },
  /**
   * 从刷新事件中移除某页面的刷新事件
   */
  removeRefreshEvent(state, { pageName }) {
    delete state.refreshEvents[pageName];
  },
  /**
   * 触发刷新事件中的某页面的刷新事件
   */
  triggerRefreshEvent(state, { pageName }) {
    const event = state.refreshEvents[pageName];
    if (event) {
      event();
    }
  },
};
export default mutation;
