/*
 * @Author: Kaiser
 * @Date: 2019-07-29 09:59:00
 * @Last Modified by: Lhy
 * @Last Modified time: 2020-04-26 15:16:43
 */

import asyncImport from '@/utils/import_production';

const routes = [
  {
    path: '/shop_list',
    name: 'shop_list',
    meta: {
      name: '店铺列表',
      category: 'menu',
    },
    component: asyncImport('/shop/shop_list'),
    children: [
      {
        path: '/shop_detail',
        name: 'shop_detail',
        meta: {
          name: '店铺详情',
          category: 'page',
        },
        props: route => ({
          shopId: route.query.shopId,
        }),
        component: asyncImport('/shop/shop_detail'),
      },
      {
        path: '/shop_create',
        name: 'shop_create',
        meta: {
          name: '创建店铺',
          category: 'page',
        },
        component: asyncImport('/shop/shop_create'),
      },
      {
        path: '/customer_detail',
        name: 'customer_detail',
        meta: {
          name: '客户详情',
          category: 'page',
        },
        props: route => ({
          relationId: route.query.relationId,
          sellerId: route.query.sellerId,
          buyerId: route.query.buyerId,
        }),
        component: asyncImport('/shop/customer_detail'),
      },
    ],
  },
  {
    path: '/merchant_list',
    name: 'merchant_list',
    meta: {
      name: '商家列表',
      category: 'menu',
    },
    component: asyncImport('/shop/merchant_list'),
    children: [
      {
        path: '/merchant_detail',
        name: 'merchant_detail',
        meta: {
          name: '商家详情',
          category: 'page',
        },
        props: route => ({
          shopId: route.query.shopId,
        }),
        component: asyncImport('/shop/merchant_detail'),
      },
      {
        path: '/supplier_detail',
        name: 'supplier_detail',
        meta: {
          name: '供应商详情',
          category: 'page',
        },
        props: route => ({
          shopId: route.query.shopId,
        }),
        component: asyncImport('/shop/supplier_detail'),
      },
    ],
  },
  {
    path: '/shop_certification_list',
    name: 'shop_certification_list',
    meta: {
      name: '开店认证',
      category: 'menu',
    },
    component: asyncImport('/shop/shop_certification_list'),
    children: [
      {
        path: '/shop_certification_detail',
        name: 'shop_certification_detail',
        meta: {
          name: '商家详情',
          category: 'page',
        },
        component: asyncImport('/shop/shop_certification_detail'),
      },
    ],
  },
];
export default routes;
