/*
 * @Author: Kaiser
 * @Date: 2019-06-10 16:36:40
 * @Last Modified by: Kaiser
 * @Last Modified time: 2020-04-17 11:24:27
 * @Description: 该模块是数据类型相关的工具集
 */
/**
 * 获取目前的类型
 * @param {any} target
 * @returns {string} 返回参数的类型
 */
export function getType(target) {
    return Object.prototype.toString.call(target).slice(8, -1);
}
/**
 * 判断目标是都是对象类型
 * @param {any} target
 * @returns {boolean}
 */
export function isObject(targer) {
    return getType(targer) === 'Object';
}
/**
 * 判断目标是都是字符串类型
 * @param {any} target
 * @returns {boolean} true or false
 */
export function isString(target) {
    return getType(target) === 'String';
}
/**
 * 判断目标是都是数字类型
 * @param {any} target
 * @returns {boolean} true or false
 */
export function isNumber(target) {
    return getType(target) === 'Number';
}
/**
 * 判断目标是都是数组类型
 * @param {any} target
 * @returns {boolean} true or false
 */
export function isArray(target) {
    return getType(target) === 'Array';
}
/**
 * 判断对象是都为空
 * @param {object} 目标是对象类型的参数
 * @returns {boolean} true or false
 */
export function isObjectEmpty(object) {
    if (!isObject(object)) {
        throw new Error('the type of parameter is not an object');
    }
    if (Object.keys(object).length) {
        return false;
    }
    return true;
}
/**
 * 判断数组是否为空
 * @param {object} 目标是数组类型的参数
 * @returns {boolean} true or false
 */
export function isArrayEmpty(arr) {
    if (isArray(arr)) {
        throw new Error('the type of parameter is not an array');
    }
    if (arr.length) {
        return false;
    }
    return true;
}
