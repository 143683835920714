/*
 * @Author: Kaiser
 * @Date: 2019-08-10 18:45:08
 * @Last Modified by: lxq
 * @Last Modified time: 2019-08-30 16:54:07
 * @Description: ui模块的action定义
 */

const errorMsgs = [];

const action = {
  /**
   * 清除/初始化 state 中的数据
   * @param {Object} vuex 默认参数
   */
  clearState({ commit }) {
    commit('clearState');
  },
  /**
   * 展示进度条
   * @param {Object} {commit} 默认参数
   * @param {String} status  start or done
   */
  setNProgress({ commit }, status = 'start') {
    if (['start', 'done'].includes(status)) {
      commit('nprogressHandler', status);
      return;
    }
    throw new Error('please enter a correct value of status');
  },
  /**
   * 错误信息提示
   * @param {Object} {commit} 默认参数
   * @param {String} message 展示的是信息
   */
  showErrorMessage({ commit }, message) {
    const msg = message.toString();
    // 若已有相同的错误提示正在展示,则阻断
    if (errorMsgs.includes(msg)) {
      return;
    }
    // 将将要展示的错误提示入栈
    errorMsgs.push(msg);
    commit('messageHandler', {
      type: 'error',
      message: msg,
      // 当错误提示完成是出栈
      onClose: () => {
        const index = errorMsgs.indexOf(msg);
        if (index !== -1) {
          errorMsgs.splice(index, 1);
        }
      },
    });
  },
  /**
   * 控制导航显示器的显示隐藏
   * @param {Object} {commit} 默认参数
   */
  switchNavMenu({ commit }) {
    commit('switchNavMenu');
  },
  /**
   * 增加标签
   * @param {Object} {commit} 默认参数
   * @param {Object} route 需要增加的路由信息
   */
  addTab({ commit }, route) {
    commit('addTab', route);
  },
  /**
   * 移除便签
   * @param {Object} {commit} 默认参数
   * @param {Object} tabName 需要移除的路由名字
   */
  removeCurrentTab({ commit }, tabName) {
    return new Promise((resolve) => {
      commit('removeTab', tabName);
      resolve({ tabName });
    });
  },
  /**
   * 移除其他便签
   * @param {Object} {commit} 默认参数
   * @param {Object} tabName 不移除的路由名字 为空则移出所有标签
   */
  removeOtherTabs({ commit }, tabName = '') {
    return new Promise((resoleve) => {
      commit('removeOtherTabs', tabName);
      resoleve({ tabName });
    });
  },
  /**
   * 保存tabs到session中
   * @param {Object} {commit} 默认参数
   */
  saveTabs({ commit }) {
    commit('saveTabs');
  },
  /**
   * 关闭当前页面
   */
  // eslint-disable-next-line no-unused-vars
  closePage({ commit }, routeName = '') {
    $vue.$tabs.closePage(routeName);
  },
  /**
   * 刷新当前页面
   */
  // eslint-disable-next-line no-unused-vars
  refreshPage({ commit }, routeName = '') {
    $vue.$tabs.refreshPage(routeName);
  },
  /**
   * 往刷新事件中添加某页面的刷新事件
   */
  addRefreshEvent({ commit }, { pageName, pageRefreshEvent }) {
    commit('addRefreshEvent', { pageName, pageRefreshEvent });
  },
  /**
   * 从刷新事件中移除某页面的刷新事件
   */
  removeRefreshEvent({ commit }, { pageName }) {
    commit('removeRefreshEvent', { pageName });
  },
  /**
   * 触发刷新事件中的某页面的刷新事件
   */
  triggerRefreshEvent({ commit }, { pageName }) {
    commit('triggerRefreshEvent', { pageName });
  },
};

export default action;
