/*
 * @Author: Kaiser
 * @Date: 2019-06-10 09:50:18
 * @Last Modified by: Kaiser
 * @Last Modified time: 2019-08-29 15:18:02
 */
import simpleDeepClone from '@/utils/clone';

const token = localStorage.getItem('token') || '';

const initState = {
  // 用户令牌
  token: '',
  // 用户信息
  user: {
    url: '', // 用户头像
    realName: '', // 用户名字
    userName: '', // 用户账号
    id: '', // userId
  },
  // 保存权限路由,目前用于breadcrumb
  routes: [],
  // 菜单
  menu: [],
};

const state = simpleDeepClone({
  ...initState,
  token,
});

export { initState };

export default state;
